import * as Sentry from '@sentry/astro'

const apiUrl = import.meta.env.PUBLIC_IDX_BACKEND_URL || '/api'
const devApiUrl = '/api.dev'

Sentry.init({
  dsn: 'https://8d68551a0a834e2b4ef7ca4a00e5b8ed@o4506913974910976.ingest.us.sentry.io/4506914178400256',
  ignoreErrors: ['Network Error', 'Upscope', 'code 401'],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error']
    }),
    Sentry.extraErrorDataIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      minReplayDuration: 2000,
      networkCaptureBodies: true,
      networkDetailAllowUrls: ['localhost', devApiUrl],
      networkRequestHeaders: [
        'Accept',
        'Accept-Language',
        'Accept-Encoding',
        'Content-Type',
        'Content-Length',
        'Connection',
        'Encoding',
        'Host',
        'Origin',
        'Referer',
        'Path',
        'Sec-Ch-Ua',
        'Sec-Ch-Ua-Mobile',
        'Sec-Ch-Ua-Platform',
        'User-Agent'
      ],
      networkResponseHeaders: [
        'Access-Control-Allow-Methods',
        'Access-Control-Allow-Headers',
        'Access-Control-Allow-Credentials',
        'Access-Control-Allow-Origin',
        'Content-Encoding',
        'Content-Type',
        'Content-Length',
        'Date',
        'Etag',
        'Expires',
        'Server',
        'Status',
        'Strict-Transport-Security',
        'Vary',
        'Via',
        'X-Ami-Version',
        'X-Frame-Options',
        'X-Intercom-Version',
        'X-Request-Id',
        'X-Request-Queueing',
        'X-Runtime',
        'X-Firefox-Spdy',
        'X-Xss-Protection'
      ]
    })
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', apiUrl, window.location.origin]
})
